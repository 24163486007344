import { navigate } from "gatsby";
import React from "react";
import styled from "styled-components";

const SearchFormWrapper = styled.input.attrs(props => ({
    type: "text",
    size: props.size || "0.3rem",
    placeholder: "🔍"
}))`
    display: block;
    font-size: 1em;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 90%;
    margin: 10px auto 0 auto;
    padding: 2px 10px;
`;

const SearchFrom = () => {
    return (
        <SearchFormWrapper onKeyPress={e => {
            if (e.key == 'Enter') {
                e.preventDefault();
                navigate(`/search?q=${e.target.value}`)
            }
        }} >
        </SearchFormWrapper>
    )
}

export default SearchFrom;