import React, { Component } from "react";
import styled from "styled-components";
import onClickOutside from "react-onclickoutside";

const Container = styled.div`
    // visibility: ${(props) => (props.show ? "visiable" : "hidden")};
    height: ${(props) => (props.show ? "160px" : "0")};
    overflow: hidden;
    margin-left: 10px;
    transition: all 0.3s cubic-bezier(0.16, 0.76, 0.4, 0.99);
    position: relative;
    z-index: 2;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;

    &:after {
        content: "";
        position: absolute;
    }
`;

class ClickPopup extends Component {
    handleClickOutside = (e) => {
        this.props.close();
    };
    render() {
        const { open, children } = this.props;
        return <Container show={open}>{children}</Container>;
    }
}

export default onClickOutside(ClickPopup);
